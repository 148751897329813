import { FormattedMessage } from "react-intl";
// import { useNavigate } from "react-router-dom";

export default function BtnAnimated({ text, onClick }) {
  return (
    <div
      className="relative w-40 h-12 bg-[#343e4a] block text-lg cursor-pointer select-none 
      overflow-hidden text-[#e92763] font-medium hover:text-white group"
      onClick={() => {
        onClick();
      }}
    >
      <p
        className="
          absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10 font-normal text-nowrap
        "
      >
        <FormattedMessage id={text} />
      </p>
      <span
        className="absolute inset-0 bg-transparent border-4 border-[#e92763] before:content-[''] before:block before:absolute before:w-[8%] 
        before:h-[500%] before:bg-[#343e4a] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 
        before:-rotate-[60deg] before:transition-all before:duration-300 group-hover:before:-rotate-90 group-hover:before:w-full 
        group-hover:before:bg-[#e92763] active:before:bg-[#b13f63]"
      ></span>
    </div>
  );
}
