// import BtnAnimated from "../components/BtnAnimated";

import { useState } from "react";
import PopUpMedia from "../components/PopUpMedia";

import bentley_skyline_edition from "../assets/images/Skyline V.E_Int02rs.jpg";
import bentley_azure from "../assets/images/BentleyAzure_Int.01_rs.jpg";
import bentley_bentayga from "../assets/images/Bentayga1.jpg";
import video_Bentley_Azure from "../assets/videos/Bentley_Azure.mp4";
import video_Bentley_bentayga from "../assets/videos/Bentley_bentayga.mp4";
import video_Bentley_skyline from "../assets/videos/Bentley_skyline.mp4";

export default function Video() {
  const videos = [
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      image: bentley_skyline_edition,
      src: video_Bentley_skyline,
    },
    {
      name: "Bentley Flying Spur Azure",
      image: bentley_azure,
      src: video_Bentley_Azure,
    },
    {
      name: "Bentley Bentayga",
      image: bentley_bentayga,
      src: video_Bentley_bentayga,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      image: bentley_skyline_edition,
      src: video_Bentley_skyline,
    },
    {
      name: "Bentley Flying Spur Azure",
      image: bentley_azure,
      src: video_Bentley_Azure,
    },
    {
      name: "Bentley Bentayga",
      image: bentley_bentayga,
      src: video_Bentley_bentayga,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      image: bentley_skyline_edition,
      src: video_Bentley_skyline,
    },
    {
      name: "Bentley Flying Spur Azure",
      image: bentley_azure,
      src: video_Bentley_Azure,
    },
    {
      name: "Bentley Bentayga",
      image: bentley_bentayga,
      src: video_Bentley_bentayga,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      image: bentley_skyline_edition,
      src: video_Bentley_skyline,
    },
    {
      name: "Bentley Flying Spur Azure",
      image: bentley_azure,
      src: video_Bentley_Azure,
    },
    {
      name: "Bentley Bentayga",
      image: bentley_bentayga,
      src: video_Bentley_bentayga,
    },
  ];

  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);

  return (
    <div className="py-32 px-20 bg-slate-950">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {videos.map((item, index) => {
          return (
            <div
              key={index}
              className="relative bg-[#282f39] rounded-lg cursor-pointer transition-all group hover:scale-105"
              onClick={() => {
                setIsShowPopUp(true);
                setCurrentVideo(index);
              }}
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-[300px] object-cover rounded-lg"
              />
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="w-14 h-14 bg-[#e92763] bg-opacity-70 rounded-full ring-2 ring-white grid place-items-center transition group-hover:bg-opacity-90">
                  {/* <span className="sr-only">Watch the video</span> */}
                  <svg
                    className="ml-1 w-5"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}

        {isShowPopUp && (
          <PopUpMedia
            type={"video"}
            src={videos[currentVideo].src}
            setIsShowPopUp={setIsShowPopUp}
          />
        )}
      </div>
    </div>
  );
}
