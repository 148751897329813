// import BtnAnimated from "../components/BtnAnimated";

import { useState } from "react";
import PopUpMedia from "../components/PopUpMedia";

import image1 from "../assets/images/Bentayga1.jpg";
import image2 from "../assets/images/Bentayga2.jpg";
import image3 from "../assets/images/BentleyAzure_Int.01_rs.jpg";
import image4 from "../assets/images/Skyline V.E_Ext01.jpg";
import image5 from "../assets/images/Skyline V.E_Int02rs.jpg";
import image6 from "../assets/images/Skyline VE_Int.02rs.jpg";

export default function Gallery() {
  const images = [
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      src: image1,
    },
    {
      name: "Bentley Flying Spur Azure",
      src: image2,
    },
    {
      name: "Bentley Bentayga",
      src: image3,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      src: image4,
    },
    {
      name: "Bentley Flying Spur Azure",
      src: image5,
    },
    {
      name: "Bentley Bentayga",
      src: image6,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      src: image1,
    },
    {
      name: "Bentley Flying Spur Azure",
      src: image2,
    },
    {
      name: "Bentley Bentayga",
      src: image3,
    },
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      src: image4,
    },
    {
      name: "Bentley Flying Spur Azure",
      src: image5,
    },
    {
      name: "Bentley Bentayga",
      src: image6,
    },
  ];

  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  return (
    <div className="py-32 px-20 bg-slate-950">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {images.map((item, index) => {
          return (
            <div
              key={index}
              className="relative bg-[#282f39] rounded-lg cursor-pointer transition-all hover:scale-105"
              onClick={() => {
                setIsShowPopUp(true);
                setCurrentImage(index);
              }}
            >
              <img
                src={item.src}
                alt={item.name}
                className="w-full h-[300px] object-cover rounded-lg"
              />
              {/* <div
                className="text-white text-2xl absolute bottom-0 left-1/2 transform 
                -translate-x-1/2 text-nowrap"
              >
                {item.name}
              </div> */}
            </div>
          );
        })}
      </div>
      {isShowPopUp && (
        <PopUpMedia
          type={"image"}
          src={images[currentImage].src}
          setIsShowPopUp={setIsShowPopUp}
        />
      )}
    </div>
  );
}
