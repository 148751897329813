import { configureStore } from "@reduxjs/toolkit";
import { languageReducer } from "./features/LanguageSlice";
import { bgMusicReducer } from "./features/bgMusicSlice";
// import GuideReducer from "./features/GuideSlice";
//

const store = configureStore({
  reducer: {
    // guide: GuideReducer,
    language: languageReducer,
    bgMusic: bgMusicReducer,
  },
});

export default store;
