import { useSelector } from "react-redux";

export default function Showroom() {
  const { locale } = useSelector((state) => state.language);

  return (
    <div className="h-lvh relative">
      <iframe
        src={`${
          locale === "vi"
            ? "file360/showroom-bentley/index.html"
            : "file360/showroom-bentley/indexEN.html"
        }`}
        title="HouseSampleView"
        width="100%"
        height="100%"
      />
    </div>
  );
}
