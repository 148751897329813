import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMuted: false,
};

const bgMusicSlice = createSlice({
  name: "bgMusic",
  initialState,
  reducers: {
    setIsMuted: (state, action) => {
      state.isMuted = action.payload;
      localStorage.setItem("bgMusic", JSON.stringify(state.isMuted));
    },
  },
});

export const bgMusicReducer = bgMusicSlice.reducer;
export const { setIsMuted } = bgMusicSlice.actions;
