import { useNavigate } from "react-router-dom";
import BtnAnimated from "../components/BtnAnimated";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { selectLanguage } from "../redux/features/LanguageSlice";

import chuotIcon from "../assets/icons/chuot.png";
import fingerIcon from "../assets/icons/finger.png";
import loadingBg from "../assets/images/loadingBg.png";
import bentleyLogo from "../assets/icons/logo-benley-white.png";
import infoIcon from "../assets/icons/question.png";
import soundIcon from "../assets/icons/music.png";
import muteIcon from "../assets/icons/mute.png";
import { setIsMuted } from "../redux/features/bgMusicSlice";

export default function Loading() {
  const navigate = useNavigate();

  const { locale } = useSelector((state) => state.language);
  const { isMuted } = useSelector((state) => state.bgMusic);
  const dispatch = useDispatch();

  return (
    <div className="relative">
      <img src={bentleyLogo} alt="logo" className="w-44 absolute left-6" />
      <div className=" absolute top-12 right-6">
        {/* <div className="mb-4">
            <FormattedMessage id="loading_page.model.language" />
          </div> */}
        <div className="flex gap-4">
          <div
            className={`flex items-center justify-center w-12 h-12 rounded-full text-white cursor-pointer
                ${
                  locale === "vi"
                    ? "bg-[#e92763] border-none"
                    : "border border-white"
                }
                hover:bg-[#e92763] hover:text-white transition-all duration-300
            `}
            onClick={() => {
              dispatch(selectLanguage("vi"));
            }}
          >
            VI
          </div>
          <div
            className={`flex items-center justify-center w-12 h-12 rounded-full text-white cursor-pointer
                ${
                  locale === "en"
                    ? "bg-[#e92763] border-none"
                    : "border border-white"
                }
                hover:bg-[#e92763] hover:text-white transition-all duration-300
            `}
            onClick={() => {
              dispatch(selectLanguage("en"));
            }}
          >
            EN
          </div>
        </div>
      </div>

      <div
        className="absolute left-1/2 bottom-0 lg:top-1/2 transform -translate-y-1/2 -translate-x-1/2 
      lg:-translate-x-0 lg:left-6 flex justify-center items-center lg:flex-col"
      >
        {!isMuted ? (
          <img
            src={soundIcon}
            alt="sound"
            className="w-10 cursor-pointer hover:scale-110 transition-all"
            onClick={() => dispatch(setIsMuted(!isMuted))}
          />
        ) : (
          <img
            src={muteIcon}
            alt="mute"
            className="w-10 cursor-pointer hover:scale-110 transition-all"
            onClick={() => dispatch(setIsMuted(!isMuted))}
          />
        )}
        <img
          src={infoIcon}
          alt="info"
          className="w-10 lg:mt-6 cursor-pointer hover:scale-110 transition-all"
        />
      </div>
      <div
        className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 text-center
        w-[350px] md:w-[400px] bg-[#343e4a] rounded-md p-6 bg-opacity-80 justify-center items-center flex flex-col
        gap-6 text-white
      "
      >
        <h1 className="text-2xl uppercase">
          <FormattedMessage id="loading_page.model.title" />
        </h1>
        <div className="flex gap-4 items-center ">
          <img
            src={chuotIcon}
            alt="chuot"
            className="w-16 object-contain mx-auto"
          />
          <p>
            <FormattedMessage id="loading_page.model.or" />
          </p>
          <img
            src={fingerIcon}
            alt="finger"
            className="w-16 object-contain mx-auto"
          />
        </div>
        <p className="text-sm">
          <FormattedMessage id="loading_page.model.content" />
        </p>
        {/* <div
          className="bg-[#e92763] px-4 py-2 rounded-lg cursor-pointer hover:opacity-90"
          onClick={() => {
            navigate("/roundcar");
          }}
        >
          Xem xe
        </div> */}
        <BtnAnimated
          text="loading_page.model.button"
          onClick={() => navigate("/showroom")}
        />
      </div>
      <img
        src={loadingBg}
        alt="loading"
        className="w-full h-screen object-cover"
      />
    </div>
  );
}
