import { useState } from "react";
import BtnAnimated from "../components/BtnAnimated";
import { FormattedMessage } from "react-intl";

import bentley_skyline_edition from "../assets/images/Skyline V.E_Int02rs.jpg";
import bentley_azure from "../assets/images/BentleyAzure_Int.01_rs.jpg";
import bentley_bentayga from "../assets/images/Bentayga1.jpg";

export default function Interior() {
  const cars = [
    {
      name: "Bentley GTC S Skyline Vietnam Edition",
      image: bentley_skyline_edition,
      scene: "scene_0285_interior",
    },
    {
      name: "Bentley Flying Spur Azure",
      image: bentley_azure,
      scene: "scene_0566_interior",
    },
    {
      name: "Bentley Bentayga",
      image: bentley_bentayga,
      scene: "scene_0335_interior",
    },
  ];

  const [isShow, setIsShow] = useState(false);

  const [currentCar, setCurrentCar] = useState(0);

  return (
    <div className="bg-slate-950">
      {!isShow ? (
        <div className="flex lg:h-lvh justify-start items-center py-20 px-10 pb-32 lg:px-0 lg:pb-0 flex-col gap-20">
          <div className="text-[#e92763] text-4xl uppercase font-bold">
            <FormattedMessage id="interior_car.title" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 px-20">
            {cars.map((item, index) => {
              return (
                <div key={index} className="relative bg-[#282f39] rounded-lg">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-[300px] object-cover rounded-lg"
                  />
                  <div
                    className="rounded-lg absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center 
              items-center gap-4 opacity-0 hover:opacity-100 transition-all duration-300
                p-10 text-center
              "
                  >
                    <div className="text-white text-2xl">{item.name}</div>
                    <BtnAnimated
                      text="interior_car.button"
                      onClick={() => {
                        setIsShow(true);
                        setCurrentCar(index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="h-lvh relative">
          <iframe
            src={`file360/showroom-bentley/index.html?startscene=${cars[currentCar].scene}`}
            title="HouseSampleView"
            className="z-50"
            width="100%"
            height="100%"
          />
          <div
            className="absolute left-6 top-6 cursor-pointer z-50"
            onClick={() => {
              setIsShow(false);
            }}
          >
            <div className="w-12 h-12 bg-[#e92763] bg-opacity-70 rounded-full ring-2 ring-white grid place-items-center hover:bg-opacity-90 transition">
              {/* <span className="sr-only">Watch the video</span> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-7"
                viewBox="0 0 512 512"
              >
                <path
                  d="M177.5 414c-8.8 3.8-19 2-26-4.6l-144-136C2.7 268.9 0 262.6 0 256s2.7-12.9 7.5-17.4l144-136c7-6.6 17.2-8.4 26-4.6s14.5 12.5 14.5 22l0 72 288 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-288 0 0 72c0 9.6-5.7 18.2-14.5 22z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
