import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import { useEffect, useRef } from "react";
import bgMusic from "../assets/musics/bgMusic.mp3";
import infoIcon from "../assets/icons/question.png";
import soundIcon from "../assets/icons/music.png";
import muteIcon from "../assets/icons/mute.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsMuted } from "../redux/features/bgMusicSlice";
import bentleyLogo from "../assets/icons/logo-benley-white.png";

export default function SharedLayout() {

  const {isMuted} = useSelector((state) => state.bgMusic);
  const dispatch = useDispatch();

  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = 0.5; // Đặt âm lượng là 50%
      audio.play().catch((err) => console.log("Trình duyệt đã chặn autoplay:", err));
      audio.muted = isMuted;
    }
  }, [isMuted]);

  const toggleMute = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !isMuted;
      dispatch(setIsMuted(!isMuted));
    }
  };

  return (
    <div>
      <audio ref={audioRef} src={bgMusic} loop className="hidden" />
      <img src={bentleyLogo} alt="logo" className="w-44 fixed left-4 top-[-20px] z-40" />
      <div className="fixed z-40 top-1/2 left-4 transform -translate-y-1/2">
        {!isMuted ? (
          <img
            src={soundIcon}
            alt="sound"
            className="w-10 cursor-pointer hover:scale-110 transition-all mb-4"
            onClick={toggleMute}
          />
        ) : (
          <img
            src={muteIcon}
            alt="mute"
            className="w-10 cursor-pointer hover:scale-110 transition-all mb-4"
            onClick={toggleMute}
          />
        )}
        <img src={infoIcon} alt="info" className="w-10 lg:mt-6 cursor-pointer hover:scale-110 transition-all" />
      </div>
      <Outlet />
      {/* Navigation */}
      <Menu />
    </div>
  );
}
