export default function PopUpMedia({ type, src, setIsShowPopUp }) {
  return (
    <div
      className="h-lvh w-full fixed z-50 top-0 left-0 flex justify-center items-center bg-slate-800 bg-opacity-80"
      onClick={() => setIsShowPopUp(false)}
    >
      <div
        className="w-[80%] h-[80vh] mt-[-120px] bg-black rounded-lg p-4 relative"
        onClick={(e) => e.stopPropagation()}
      >
        {type === "video" ? (
          <video
            className="videoIntro"
            id="videoIntro"
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              zIndex: 1,
            }}
            controls
            autoPlay
            src={src}
            loop
            // playsInline=""
            // preload="auto"
          />
        ) : (
          <img className="w-full h-full object-contain" src={src} alt="360" />
        )}
        <div
          className="absolute cursor-pointer top-4 right-4 w-10 h-10 bg-[#e92763] 
        rounded-full ring-2 ring-white grid place-items-center transition hover:bg-opacity-90"
          onClick={() => setIsShowPopUp(false)}
        >
          {/* <span className="sr-only">Watch the video</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="none"
            className="w-5"
          >
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
