import { Suspense, useEffect } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SharedLayout from "./pages/SharedLayout";
import Error from "./pages/Error";
import Loading from "./pages/Loading";
import { menu } from "./components/Menu";
import { selectLanguage } from "./redux/features/LanguageSlice";
import { setIsMuted } from "./redux/features/bgMusicSlice";

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;

  const dispatch = useDispatch();

  useEffect(() => {
    const savedCartItems = localStorage.getItem("language");
    const savedBgMusic = localStorage.getItem("bgMusic");
    if (savedCartItems) {
      dispatch(selectLanguage(JSON.parse(savedCartItems)));
    }
    if (savedBgMusic) {
      dispatch(setIsMuted(JSON.parse(savedBgMusic)));
    }
  }, [dispatch]);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Routes>
              <Route path="" index element={<Loading />} />
              <Route element={<SharedLayout />}>
                {menu.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      index
                      path={item.to}
                      element={item.element}
                    />
                  );
                })}
              </Route>
              <Route path="*" element={<Error />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
