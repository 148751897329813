import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

export default function MenuItem({ src, srcHover, title, to }) {
  const [over, setOver] = useState(false);

  const isActive = useLocation().pathname === to;

  return (
    <NavLink
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
      className={({ isActive }) =>
        `flex justify-center items-center hover:bg-[#343e4a] hover:text-[#e92763] w-full px-5 lg:px-10 gap-2 relative last:rounded-r-lg ${
          isActive ? "bg-[#343e4a] text-[#e92763]" : "text-white"
        }`
      }
      to={to}
    >
      <img
        src={over || isActive ? srcHover : src}
        className="lg:w-10 w-full object-contain"
        alt={title}
      />
      <div className={`uppercase text-nowrap hidden lg:block`}>
        <FormattedMessage id={`nav.menu.${title}`} />
      </div>
      <div className="absolute border-l-2 w-full h-1/2"></div>
    </NavLink>
  );
}
