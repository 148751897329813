import Gallery from "../pages/Gallery";
import Interior from "../pages/Interior";
import Showroom from "../pages/Showroom";
import Video from "../pages/Video";
import MenuItem from "./MenuItem";

import bentleyLogo from "../assets/icons/Bentley-Logo.wine.png";

import interiorIcon from "../assets/icons/interior-white.png";
import interiorHoverIcon from "../assets/icons/interior-pink.png";

import showroomIcon from "../assets/icons/icon-showroom-white.png";
import showroomHoverIcon from "../assets/icons/icon-showroom-pink.png";

import videoIcon from "../assets/icons/video.png";
import videoHoverIcon from "../assets/icons/video-pink.png";

import galleryIcon from "../assets/icons/gallery-white.png";
import galleryHoverIcon from "../assets/icons/gallery-pink.png";

import { useNavigate } from "react-router-dom";

export const menu = [
  // {
  //   icon: carIcon,
  //   title: "round_car",
  //   to: "/roundcar",
  //   element: <RoundCar />,
  // },
  {
    icon: showroomIcon,
    iconHover: showroomHoverIcon,
    title: "showroom_tour",
    to: "/showroom",
    element: <Showroom />,
  },
  {
    icon: interiorIcon,
    iconHover: interiorHoverIcon,
    title: "interior_car",
    to: "/interior",
    element: <Interior />,
  },

  {
    icon: videoIcon,
    iconHover: videoHoverIcon,
    title: "video",
    to: "/video",
    element: <Video />,
  },
  {
    icon: galleryIcon,
    iconHover: galleryHoverIcon,
    title: "gallery",
    to: "/gallery",
    element: <Gallery />,
  },
];

export default function Menu() {

  const navigate = useNavigate();

  return (
    <div
      className="flex fixed bottom-4 w-[90%] md:w-[80%] bg-[#282f39]
      z-50 shadow-2xl rounded-lg left-1/2 transform -translate-x-1/2
    "
    >
      <div className="mx-[-20px]">
        <img
          src={bentleyLogo}
          alt="logo"
          className="object-contain my-[-24%] lg:mr-[-100px] relative z-40 w-full scale-[0.55] cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>
      {menu.map((item, index) => {
        return (
          <MenuItem
            key={index}
            title={item.title}
            src={item.icon}
            srcHover={item.iconHover}
            to={item.to}
          />
        );
      })}
    </div>
  );
}
